import { Alert, ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import { useGetIncidentsId } from "../../../../.rest-hooks/incidents";
import {
  getGetIncidentsIdPredictionsQueryKey,
  getGetPredictionsQueryKey,
  usePostPredictionsStartRun,
} from "../../../../.rest-hooks/predictions";
import {
  automatedPredictionSimulators,
  formatPredictionAnalyst,
  isAutomatedPredictionAnalyst,
} from "../../../config/predictionAnalyst";
import { isProduction } from "../../../utils/getEnvironment";
import RerunPredictionForm, {
  getDefaultValues,
  type RerunPredictionFormValues,
  type RerunPredictionStrategy,
} from "./RerunPredictionForm";

interface GetRerunPredictionMessageParams {
  analyst: string;
  strategy: RerunPredictionStrategy;
}

const getSuccessToastTitle = ({
  analyst,
  strategy,
}: GetRerunPredictionMessageParams): string => {
  switch (strategy) {
    case "all": {
      return "Started new prediction run";
    }
    case "single": {
      const simulatorLabel = formatPredictionAnalyst(analyst);
      return `${simulatorLabel} prediction re-run`;
    }
  }
};

const getErrorAlertTitle = ({
  analyst,
  strategy,
}: GetRerunPredictionMessageParams): string => {
  switch (strategy) {
    case "all": {
      return "Unable to re-run predictions";
    }
    case "single": {
      const simulatorLabel = formatPredictionAnalyst(analyst);
      return `Unable to re-run ${simulatorLabel} prediction`;
    }
  }
};

interface RerunPredictionModalFormProps {
  analyst: string;
  incidentId: string;
  isOpen: boolean;
  onCancel: () => void;
  onComplete: () => void;
}

const RerunPredictionModalForm = ({
  analyst,
  incidentId,
  isOpen,
  onCancel,
  onComplete,
}: RerunPredictionModalFormProps) => {
  const queryClient = useQueryClient();
  const [formError, setFormError] = useState<RerunPredictionStrategy | null>(
    null,
  );

  const { data } = useGetIncidentsId(incidentId);

  const { mutateAsync: startPredictionRun } = usePostPredictionsStartRun();

  const form = useForm<RerunPredictionFormValues>({
    defaultValues: getDefaultValues(),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    if (isOpen) {
      reset(getDefaultValues());
      setFormError(null);
    }
  }, [isOpen, reset]);

  const onSubmit: SubmitHandler<RerunPredictionFormValues> = async (values) => {
    setFormError(null);

    if (!isAutomatedPredictionAnalyst(analyst)) {
      showToast({
        variant: "error",
        title: "Unable to re-run prediction",
        message: "Could not determine appropriate simulator",
      });
      return;
    }

    const { strategy } = values;
    const simulator = automatedPredictionSimulators[analyst];
    const incidentName = data?.data.data.attributes.name;

    try {
      await startPredictionRun(
        {
          data: {
            incidentId,
            debug: !isProduction(),
            simulators: strategy === "single" ? [simulator] : undefined,
          },
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries({
              queryKey: getGetIncidentsIdPredictionsQueryKey(incidentId),
            });
            void queryClient.invalidateQueries({
              queryKey: getGetPredictionsQueryKey(),
            });

            showToast({
              title: getSuccessToastTitle({ analyst, strategy }),
              message: incidentName,
              variant: "success",
            });
          },
        },
      );
      onComplete();
    } catch {
      setFormError(strategy);
    }
  };

  return (
    <ModalForm
      error={
        formError && (
          <Alert
            title={getErrorAlertTitle({ analyst, strategy: formError })}
            variant="error"
          >
            Please wait and try again. If your problem persists contact ICT
            Service Desk.
          </Alert>
        )
      }
      id="rerunPredictionModal"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onCancel}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      title="Re-run prediction"
    >
      <FormProvider {...form}>
        <RerunPredictionForm />
      </FormProvider>
    </ModalForm>
  );
};

export default RerunPredictionModalForm;
