/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type PredictionAssessmentType =
  (typeof PredictionAssessmentType)[keyof typeof PredictionAssessmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PredictionAssessmentType = {
  endorsed: "endorsed",
  valid: "valid",
  "low-confidence": "low-confidence",
} as const;
